import PropTypes from "prop-types";

export const commentShape = PropTypes.shape({
    id__normalized: PropTypes.string,
    author: PropTypes.string,
    date: PropTypes.string,
    content: PropTypes.string,
    parentId: PropTypes.string,
    avatarUrl: PropTypes.string,
});

export const pageImagesShape = PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
        relativePath: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.shape({
                layout: PropTypes.string.isRequired,
                placeholder: PropTypes.string.isRequired,
                pngOptions: PropTypes.shape({
                    compressionSpeed: PropTypes.number.isRequired,
                }).isRequired,
                quality: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
    })),
});

export const blogDataShape = PropTypes.shape({
    markdownRemark: PropTypes.shape({
        htmlAst: PropTypes.string,
        frontmatter: PropTypes.string,
    }),
    allPagesComments: PropTypes.shape({
        nodes: PropTypes.arrayOf(commentShape),
        totalCount: PropTypes.number,
    }).isRequired,
    pageImages: pageImagesShape,
});

export const propDataTypes = {
    data: blogDataShape,
};

export const blogDataDefaults = {
    data: {
        markdownRemark: {
            htmlAst: null,
            frontmatter: null,
        },
        allPagesComments: null,
        pageImages: null,
    },
};
